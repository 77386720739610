import React from 'react';
import './ImageBlock.css'

const ImageBlock = () => {
  return (
    <div className="image-block">
      <div className="row">
        <div className="column">
          <div className="image-container">
            <img  src="https://trustwallet.com/_next/static/media/raw.b4926dd2.svg" alt="Image 1" width={200} height={200}/>
            <div className="text-container">
              <h3>Swap trending tokens</h3>
              <p className='text-short'>Swap all the latest trending tokens, in one place.</p>
            </div>
          </div>
        </div>
        <div className="column">
          <div className="image-container">
            <img  src="https://trustwallet.com/_next/static/media/raw.df0b8611.svg" alt="Image 2" width={200} height={200}/>
            <div className="text-container">
              <h3>Reduced Gas Fees</h3>
              <p className='text-short'>Our Swap feature determines the most<br></br> gas-efficient liquidity source for each transaction.</p>
            </div>
          </div>
        </div>
        <div className="column">
          <div className="image-container">
            <img  src="https://trustwallet.com/_next/static/media/raw.7a06cf07.svg" alt="Image 3" width={200} height={200}/>
            <div className="text-container">
              <h3>We find the best rates</h3>
              <p className='text-short'>Our swap feature guarantees you a wide range of tokens<br></br> and the best prices.</p>
            </div>
          </div>
        </div>
        <div className="column">
          <div className="image-container">
            <img  src="https://trustwallet.com/_next/static/media/raw.6ac5532a.svg" alt="Image 4" width={200} height={200}/>
            <div className="text-container">
              <h3>Slippage Protection</h3>
              <p className='text-short'>Orders are distributed across multiple DEXs to minimise the<br></br> slippage effect on the concluding price.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ImageBlock;
