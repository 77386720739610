import React from 'react';
import TopMenu from './TopMenu';
import ContactBlock from './ContactBlock';
import SocialMediaBlock from './SocialMediaBlock';
import MediaRow from './MediaRow';
import ImageTextCenter from './ImageTextCenter';
import TextBlockGrid from './TextBlock';
import TextImageBlock from './TwoPhones';
import TextBlockWithScrollingImage from "./TextBlockWithScrollingImage"
import NFTBlock from "./NFT"
import ContactBlockShort from './ContactBlockShort';
import ImageWithText from './ImageMobile';
import TextBlockGridMobile from './TextBlockMobile';
import TextImageBlockMobile from './TwoPhonesMobile';
import ImageBlock from './ImageBlock';
import NFTMobile from './NFTMobile';

const App = () => {
  return (
    <div>
      <TopMenu />
      <SocialMediaBlock />
      <ContactBlock />
      <MediaRow />
      <ImageTextCenter />
      <ImageWithText /> {/*mobile */}
      <TextBlockGrid data={[
        { title: 'User Obsessed', text: 'We’re obsessed with users’ problems and constantly seek better solutions.ext here...' },
        { title: 'Ownership and accountability', text: 'We pursue outcomes with determination, passion, and grit.' },
        { title: 'Open & Collaborative', text: 'Become the most trusted hub for Web3 experiences.' },
        { title: 'Humble and growth mindset', text: 'We’re curious, open-minded, humble, and adaptable. We don’t expect or promise perfection.' },
        { title: 'Integrity', text: 'Do no evil, act in good conscience, and inspire trust by holding high standards in everything that we do.' },
        { title: 'Execution-oriented', text: 'We bias towards action, never expect perfection, done is better than perfect.' }
      ]} />
      <TextBlockGridMobile data={[
        { title: 'User Obsessed', text: 'We’re obsessed with users’ problems and constantly seek better solutions.ext here...' },
        { title: 'Ownership and accountability', text: 'We pursue outcomes with determination, passion, and grit.' },
        { title: 'Open & Collaborative', text: 'Become the most trusted hub for Web3 experiences.' },
        { title: 'Humble and growth mindset', text: 'We’re curious, open-minded, humble, and adaptable. We don’t expect or promise perfection.' },
        { title: 'Integrity', text: 'Do no evil, act in good conscience, and inspire trust by holding high standards in everything that we do.' },
        { title: 'Execution-oriented', text: 'We bias towards action, never expect perfection, done is better than perfect.' }
      ]} /> {/* Mobile */}
      <TextImageBlock />
      <TextImageBlockMobile />
      <ImageBlock />
      <TextBlockWithScrollingImage text='Trust Wallet changed the way YOU manage YOUR crypto assets. Highly recommended by lots of people!' />
      <NFTBlock />
      <NFTMobile />
      <ContactBlockShort />
      <SocialMediaBlock />
      <div style={{ backgroundColor: '#b8c0cc', padding: '5px' }}>
      <a style={{textDecoration: 'none'}} href='mailto:contact@trustwalletpro.com'><p className='nft-mobile-text' style={{ fontSize: '20px', color: 'black', textAlign: 'center', textDecoration: 'none' }}>contact@trustwalletpro.com</p></a>
      </div>
    </div>
  );
};

export default App;
