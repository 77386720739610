import React from 'react';
import './ImageText.css';

const TextImageBlockMobile = () => {
    return (
        <div className="font-mobile" style={{ display: 'block', alignItems: 'center', paddingLeft: '20px', paddingRight: '20px', backgroundColor: 'black', paddingTop: '20px', paddingBottom: '20px' }}>
            <div style={{ flex: '1', textAlign: 'center' }}>
                <p style={{ color: 'White', marginRight: '10px', width: '90%', textAlign: 'center', paddingLeft: '0%', justifyContent: 'center', fontSize: '20px' }}><span style={{ color: '#0901e9', fontSize: '30px', fontWeight: "inherit" }}>Seamless swapping options
                </span><br></br>Swap crypto directly from the Trust Wallet mobile app and browser extension.
                </p>
            </div><br></br>
            <div style={{ textAlign: 'center', justifyContent: 'center', alignContent: 'center', margin: 'auto', backgroundColor: 'black' }}>
                <img  src="https://assets.zyrosite.com/cdn-cgi/image/format=auto,w=654,h=640,fit=crop/YBgpgeD2wLhayB6z/raw.e1fdf5b0-m5KvKnL17aHV8VQx.jpeg" alt="img" style={{ maxWidth: '80%', maxHeight: '80%', margin: 'auto', backgroundColor: 'black' }} />
            </div>
        </div>
    );
};

export default TextImageBlockMobile;
