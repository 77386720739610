import React from 'react';
import './ImageText.css';

const TextImageBlock = () => {
    return (
        <div className="font" style={{ display: 'flex', alignItems: 'center', paddingLeft: '100px', paddingRight: '100px', backgroundColor: 'black', paddingTop: '50px', paddingBottom: '50px' }}>
            <div style={{ flex: '1', textAlign: 'center' }}>
                <p style={{ color: 'White', marginRight: '10px', width: '50%', textAlign: 'left', paddingLeft: '25%', justifyContent: 'center' }}><span style={{ color: '#0901e9', fontSize: '40px', fontWeight: "inherit" }}>Seamless swapping options
                </span><br></br>Swap crypto directly from the Trust Wallet mobile app and browser extension.
                </p>
            </div>
            <div style={{ textAlign: 'center', justifyContent: 'center', alignContent: 'center' }}>
                <img  src="https://assets.zyrosite.com/cdn-cgi/image/format=auto,w=654,h=640,fit=crop/YBgpgeD2wLhayB6z/raw.e1fdf5b0-m5KvKnL17aHV8VQx.jpeg" alt="img" style={{ maxWidth: '50%', maxHeight: '50%' }} />
            </div>
        </div>
    );
};

export default TextImageBlock;
