import React from 'react';
import './ImageText.css';

const ImageWithText = ({ imageUrl, text }) => {
    return (
        <div className='img-mob-block'>
            <img  src='https://trustwallet.com/_next/static/media/raw.16e2b8fb.svg' alt="Изображение" className='img-mob' />
            <p className='img-mob-text' style={{color: '#0901e9', fontSize: '35px', lineHeight:'30px', fontWeight: 'bold'}}>FOUNDED 2017</p>
            <p className='img-mob-text'>Our journey started with a clear goal: to simplify and democratize crypto, making it accessible to everyone.
            </p>
            <img  src='https://trustwallet.com/_next/static/media/raw.b373ab3f.svg' alt="Изображение" className='img-mob' />
            <p className='img-mob-text' style={{color: '#0901e9', fontSize: '35px', lineHeight:'30px', fontWeight: 'bold'}}>Trusted by 70M+ people
            </p>
            <p className='img-mob-text'>We've built the trust of millions by making Web3 safer, easier, and more seamless to access.

            </p>
            <img  src='https://trustwallet.com/_next/static/media/raw.e7c57d68.svg' alt="Изображение" className='img-mob' />
            <p className='img-mob-text' style={{color: '#0901e9', fontSize: '35px', lineHeight:'30px', fontWeight: 'bold'}}>We're not stopping there
            </p>
            <p className='img-mob-text'>We're laying the foundation to bring more people than ever safely on-chain.</p>
        </div>
    );
};

export default ImageWithText;
