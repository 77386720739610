import React from 'react';
import './TopMenu.css'; // Подключаем CSS для стилизации

const TopMenu = () => {
  return (
    <div className="top-menu">
      <div className="logo">
        <img  src="https://assets.zyrosite.com/cdn-cgi/image/format=auto,w=246,fit=crop,q=95/YBgpgeD2wLhayB6z/60f6a9787b79c7dad2d95893_trust-wallet-m2W4WaMKO7SEgEk8.png" alt="Логотип" style={{width: '196px', height:'auto'}}/>
      </div>
      <div className="links">
        <a href="/">Home</a>
      </div>
    </div>
  );
};

export default TopMenu;
