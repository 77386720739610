import React from 'react';
import './ImageText.css';

const ImageTextCenter = ({ imageUrl, text }) => {
    return (
        <div className='font' style={{ backgroundColor: '#b8c0cc' }}>
            <p className='media-text-p'>Join us as we empower millions through Trust</p>
            <div className='img-text'>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#b8c0cc', paddingBottom: '50px' }}>
                <div style={{ display: 'flex' }}>
                    <div style={{ marginRight: '100px', paddingLeft: '10%', justifyContent: 'center' }}>
                        <img  src='https://trustwallet.com/_next/static/media/raw.16e2b8fb.svg' alt="img" style={{ maxWidth: '200px', maxHeight: '200px' }} />
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <p style={{ marginRight: '50px', width: '100%', textAlign: 'left', paddingLeft: '5%', justifyContent: 'center' }}><span style={{ color: '#0901e9', fontSize: '40px', fontWeight: 'bold' }}>FOUNDED 2017</span><br></br>Our journey started with a clear goal: to simplify and democratize crypto, making it accessible to everyone.</p>
                    </div>
                </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#b8c0cc', paddingBottom: '50px' }}>
                <div style={{ display: 'flex' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                    <p style={{ marginRight: '100px', width: '100%', textAlign: 'left', paddingLeft: '0%', justifyContent: 'center' }}><span style={{ color: '#0901e9', fontSize: '40px', fontWeight: 'bold' }}>Trusted by 70M+ people</span><br></br>We've built the trust of millions by making Web3 safer, easier, and more seamless to access.</p>
                    </div>
                    <div style={{ margin: '0', paddingRight: '5%', justifyContent: 'center' }}>
                        <img  src='https://trustwallet.com/_next/static/media/raw.b373ab3f.svg' alt="img" style={{ maxWidth: '800px', maxHeight: '800px' }} />
                    </div>
                </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#b8c0cc', paddingBottom: '50px' }}>
                <div style={{ display: 'flex' }}>
                    <div style={{ marginRight: '100px', paddingLeft: '10%', justifyContent: 'center' }}>
                        <img  src='https://trustwallet.com/_next/static/media/raw.e7c57d68.svg' alt="img" style={{ maxWidth: '800px', maxHeight: '800px' }} />
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', backgroundColor: '#b8c0cc' }}>
                        <p style={{ marginRight: '100px', width: '100%', textAlign: 'left', paddingLeft: '5%', justifyContent: 'center' }}><span style={{ color: '#0901e9', fontSize: '40px', fontWeight: 'bold' }}>We're not stopping there</span><br></br>We're laying the foundation to bring more people than ever safely on-chain.</p>
                    </div>
                </div>
            </div>
            </div>
        </div>
    );
};

export default ImageTextCenter;
