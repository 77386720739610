import React from 'react';
import './ImageText.css';

const TextBlockGridMobile = ({ data }) => {
  return (
    <div style={{ backgroundColor: '#e7e9ef' }} className='font-mobile'>
      <p style={{ paddingTop: '20px', paddingBottom: '20px' }} className='media-text-values'>Our values</p>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        {/* Первый ряд */}
        <div style={{ display: 'block', justifyContent: 'left' }}>
          {data.slice(0, 3).map((item, index) => (
            <div key={index} style={{ display: 'block' }}>
              <h2 style={{ color: '#0901e9', lineHeight: '20px', marginBottom: '15px' }}>{item.title}</h2>
              <p style={{ width: '300px', textAlign: 'left', lineHeight: '20px', marginTop: '10px' }}>{item.text}</p>
            </div>
          ))}
        </div>

        {/* Второй ряд */}
        <div style={{ display: 'block', justifyContent: 'center', paddingBottom: '20px' }}>
          {data.slice(3).map((item, index) => (
            <div key={index} style={{ margin: '0 10px' }}>
              <h2 style={{ color: '#0901e9', lineHeight: '20px', marginBottom: '15px' }}>{item.title}</h2>
              <p style={{ width: '300px', textAlign: 'left', lineHeight: '20px', marginTop: '10px' }}>{item.text}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TextBlockGridMobile;
