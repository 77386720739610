import React from 'react';
import './ImageText.css';
import './Scroll.css'

const TextBlockWithScrollingImage = ({ text }) => {
  return (
    <div className='scroll'>
      <p className="font-scroll" style={{ color: 'black', width: '70%', margin: 'auto', padding: '75px'}}>{text}</p>
    </div>
  );
};

export default TextBlockWithScrollingImage;
