import React from 'react';
import './ImageText.css';

const TextBlockGrid = ({ data }) => {
  return (
    <div style={{backgroundColor: '#e7e9ef'}}className='font'>
      <p style={{paddingTop: '50px', paddingBottom: '20px'}}className='media-text-values'>Our values</p>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        {/* Первый ряд */}
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          {data.slice(0, 3).map((item, index) => (
            <div key={index} style={{ margin: '0 50px' }}>
              <h2 style={{color: '#0901e9'}}>{item.title}</h2>
              <p style={{width: '300px'}}>{item.text}</p>
            </div>
          ))}
        </div>

        {/* Второй ряд */}
        <div style={{ display: 'flex', justifyContent: 'center', paddingBottom: '50px' }}>
          {data.slice(3).map((item, index) => (
            <div key={index} style={{ margin: '0 50px' }}>
              <h2 style={{color: '#0901e9'}}>{item.title}</h2>
              <p style={{width: '300px'}}>{item.text}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TextBlockGrid;
