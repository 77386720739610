import React from 'react';
import './MediaRow.css';

const MediaRow = () => {
  return (
    <div className='yt-block'>
      <p className='media-text-text' style={{textAlign: 'center'}}>Learn more about our crypto wallet and how it can change your life.</p>
      <div className="media-row" style={{ width: '80%', backgroundColor: '#171717', justifyContent: 'center', margin: 'auto' }}>
        <div className="media-left">
          <img  src="https://assets.zyrosite.com/YBgpgeD2wLhayB6z/mobile-application-AGBGBnrKNnhJZpo6.gif" alt="phone" width="auto" height="200" style={{height: '500px', width:'auto', justifyContent: 'center', margin: 'auto'}} />
        </div>
        {/* <div className="media-right">
          <iframe width="560" height="315" src="https://www.youtube.com/embed/bxHGGwl8EGU?si=Nrhjv_P8i-7CIu5b" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
        </div> */}
      </div>
    </div>
  );
};

export default MediaRow;
