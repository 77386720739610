import React, { useState } from 'react';
import './ContactBlockShort.css';

const ContactBlockShort = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });
  const [submitted, setSubmitted] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Ваш код для отправки данных в бот Телеграм
    const message = `Short form:\nName: ${formData.name}\nEmail: ${formData.email}\nMessage:: ${formData.message}`;
    fetch('https://api.telegram.org/bot6776707841:AAF2G5TX-xfMSFsxz-Vsh3d75i9b1AdU29s/sendMessage?chat_id=6298593945&text=' + encodeURIComponent(message))
      .then(response => response.json())
      .then(data => {
        if (data.ok) {
          // Устанавливаем локальное состояние для отслеживания отправки формы
          setSubmitted(true);
          // Очистка формы после отправки
          setFormData({
            name: '',
            email: '',
            message: ''
          });
        } else {
          alert('Failed to submit form. Please try again later.');
        }
      })
      .catch(error => {
        console.error('Error:', error);
        alert('Failed to submit form. Please try again later.');
      });
  };

  //232323

  return (
    <div className="contact-block-short" style={{ backgroundColor: '#232323', margin: 'auto' }}>
      {!submitted ? ( // Проверяем, отправлена ли форма
        <div className='form_contact-short'>
          <div className="form-short">
            <form className="contact-form-short" onSubmit={handleSubmit}>
              <div className="form-group-short">
                <label htmlFor="name">Name:</label>
                <input  className='inputtt' type="text" id="name" name="name" value={formData.name} onChange={handleChange} placeholder="your name" required />
              </div>
              <div className="form-group-short">
              <label htmlFor="email">Email:</label>
                <input type="email" id="email" name="email" value={formData.email} onChange={handleChange} placeholder="your email" required />
              </div>
              <div className="form-group-short">
                <label htmlFor="name">message:</label>
                <textarea type="text" id="message" name="message" value={formData.message} onChange={handleChange} placeholder="your message" required />
              </div>
              <button type="submit-short">Submit</button>
            </form>
          </div>
        </div>
      ) : (
        <div className='form_contact-short submit-short'>
          <div className="form-short" style={{ padding: '20px' }}>
            <p className='submit-short'><span style={{ color: '#48ff90', maxWidth: 'auto' }}>Form Submitted Successfully</span></p>
          </div>
        </div>
      )}
    </div>
  );
};

export default ContactBlockShort;
