import React from 'react';
import './ImageText.css';
import './ImageBlock.css'

const NFTBlock = ({ imageUrl, text }) => {
    return (
        <div className='font' style={{ backgroundColor: 'black' }}>
            <div className='font' style={{ backgroundColor: 'black', color: 'white' }}>
                <p className='media-text-nft' style={{ color: 'white', backgroundColor: 'black', paddingLeft: '100px' }}>Explore theworld of NFTs</p>
                <p style={{ paddingLeft: '100px', paddingBottom: '50px' }}>Unlock unique experiences, own digital art, access exclusive memberships, and so much more with your NFTs</p>
                <div style={{ width: '1000px', backgroundColor: 'black', justifyContent: 'center', margin: 'auto' }}>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: 'black', paddingBottom: '50px' }}>
                        <div style={{ display: 'flex' }}>
                            <div style={{ marginRight: '100px', paddingLeft: '10%', justifyContent: 'center' }}>
                                <img  src='https://trustwallet.com/_next/static/media/raw.5a662d03.svg' alt="img" style={{ maxWidth: '300px', maxHeight: '300px' }} />
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <p style={{ marginRight: '50px', width: '100%', textAlign: 'left', paddingLeft: '5%', justifyContent: 'center' }}><span style={{ color: '#0901e9', fontSize: '45px', fontWeight: '' }}>Support for 600M+ NFTs means no more app switching
                                </span><br></br>Enjoy all your NFTs in one place. Store, showcase, and manage your NFTs across multiple blockchains.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <p className='media-text-nft-2'>Deposit, manage and explore</p>
            <p style={{ padding: '10px', margin: '0', color: 'white', backgroundColor: 'black', textAlign: 'center' }}>Trust Wallet offers you an easy way to keep all your favourite NFTs in one place.</p>
            <img alt='img' src='https://trustwallet.com/_next/static/media/raw.b8860ba8.svg' style={{ padding: '20px', width: '1000px', margin: 'auto', alignContent: 'center', justifyContent: 'center', display: 'block', paddingBottom: '40px' }}></img>

            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: 'white', padding: '50px' }}>
                <div style={{ display: 'flex' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <p style={{ marginRight: '100px', width: '100%', textAlign: 'left', paddingLeft: '0%', justifyContent: 'center' }}><span style={{ color: 'black', fontSize: '40px', fontWeight: '' }}>Stake your crypto & earn rewards in just a few steps
                        </span><br></br>By staking your crypto, you’re not just growing your portfolio, but also helping secure blockchain networks for everyone.</p>
                    </div>
                    <div style={{ margin: '0', paddingRight: '5%', justifyContent: 'center' }}>
                        <img  src='https://trustwallet.com/_next/static/media/raw.4c0336e1.svg' alt="img" style={{ maxWidth: '800px', maxHeight: '800px' }} />
                    </div>
                </div>
            </div>

            {/* <div style={{ display: 'flex', justifyContent: 'center', textAlign: 'center', backgroundColor: 'black', padding: '50px', color: 'white' }}>
                <div style={{ marginLeft: '0px', marginRight: '0px' }}>
                    <img  src="https://trustwallet.com/_next/static/media/raw.b4926dd2.svg" alt="img 1" style={{ maxWidth: '180px', maxHeight: '180px' }} />
                    <p style={{ marginRight: '50px', width: '100%', textAlign: 'center', paddingLeft: '5%', justifyContent: 'center' }}><span style={{ color: '#0901e9', fontSize: '30px', fontWeight: 'normal' }}>Contribute to Blockchain
                    </span><br></br>Participate in network security and decentralization.
                    </p>
                </div>
                <div style={{ marginRight: '100px' }}>
                    <img  src="https://trustwallet.com/_next/static/media/raw.df0b8611.svg" alt="img 2" style={{ maxWidth: '180px', maxHeight: '180px' }} />
                    <p style={{ marginRight: '50px', width: '100%', textAlign: 'center', paddingLeft: '5%', justifyContent: 'center' }}><span style={{ color: '#0901e9', fontSize: '30px', fontWeight: 'normal' }}>Learn about DeFi
                    </span><br></br>Explore more chains and expand your earning journey across DeFi.
                    </p>
                </div>

            </div> */}
                <div className="image-block">
      <div className="row">
        <div className="column">
          <div className="image-container">
            <img  src="https://trustwallet.com/_next/static/media/raw.b4926dd2.svg" alt="Img" width={200} height={200}/>
            <div className="text-container">
              <h3 style={{color: '#0901e9', fontWeight: 'normal'}}>Contribute to Blockchain</h3>
              <p className='text-short' style={{color: 'white'}}>Participate in network security and decentralization.</p>
            </div>
          </div>
        </div>
        <div className="column">
          <div className="image-container">
            <img  src="https://trustwallet.com/_next/static/media/raw.df0b8611.svg" alt="img 2" width={200} height={200}/>
            <div className="text-container">
              <h3 style={{color: '#0901e9', fontWeight: 'normal'}}>Learn about DeFi</h3>
              <p className='text-short' style={{color: 'white'}}>Explore more chains and expand your earning journey across DeFi.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
            <p className='media-text-p' style={{ textAlign: 'left', paddingLeft: '150px', paddingBottom: '0', backgroundColor: 'white' }}><span style={{ color: 'black', fontSize: '65px', fontWeight: '' }}>Download</span></p>
            <p className='media-text-p' style={{ textAlign: 'left', paddingLeft: '150px', paddingTop: '0', paddingBottom: '0', backgroundColor: 'white', lineHeight: '65px' }}><span style={{ color: '#0901e9', fontSize: '65px', fontWeight: '' }}>Trust Wallet</span></p>
            <p className='media-text-p' style={{ textAlign: 'left', paddingLeft: '150px', paddingTop: '0', backgroundColor: 'white', lineHeight: '20px' }}><span style={{ color: 'black', fontSize: '20px', fontWeight: '' }}>The Trust Wallet is available as a mobile app and desktop browser extension</span></p>
            <div style={{ display: 'flex', justifyContent: 'center', textAlign: 'center', backgroundColor: 'white', padding: '50px', color: 'white' }}>
                <div style={{ marginLeft: '100px', marginRight: '100px' }}>
                    <a href='https://apps.apple.com/app/apple-store/id1288339409?mt=8'><img alt='img' src="https://trustwallet.com/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Fimage.a073b4c3.png&w=256&q=75"  style={{ maxWidth: '150px', maxHeight: '150px' }} /></a>
                </div>
                <div style={{ marginRight: '100px' }}>
                    <a href='https://play.google.com/store/apps/details?id=com.wallet.crypto.trustapp'><img alt='img' src="https://trustwallet.com/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Fimage.5ee64b2e.png&w=256&q=75"  style={{ maxWidth: '150px', maxHeight: '150px' }} /></a>
                </div>
                <div style={{ marginRight: '100px' }}>
                    <a href='https://chrome.google.com/webstore/detail/trust-wallet/egjidjbpglichdcondbcbdnbeeppgdph'><img alt='img'  src="https://assets.zyrosite.com/cdn-cgi/image/format=auto,w=444,h=442,fit=crop,trim=0;0;2.3063063063063063;0/YBgpgeD2wLhayB6z/google_chrome_icon_-2011-YbNaN46jyEIDD581.png" style={{ maxWidth: '150px', maxHeight: '150px' }} /></a>
                </div>
                <div style={{ marginRight: '100px' }}>
                    <a href='https://trustwallet.com/download/apk'><img  src="https://assets.zyrosite.com/cdn-cgi/image/format=auto,w=448,h=446,fit=crop,trim=4.571428571428571;0;0;0/YBgpgeD2wLhayB6z/apk_format_icon_-2014-2019-A1aKaz6rEGtj72vk.png" alt="img 2" style={{ maxWidth: '150px', maxHeight: '150px' }} /></a>
                </div>
            </div>
        </div>
    );
};

export default NFTBlock;
