import React from 'react';
import './NFTMobile.css'
const NFTMobile = () => {
    return (
        <div className='nft-mobile'>
            <div>
                <p className='nft-mobile-text' style={{ fontSize: '30px' }}>Explore theworld of NFTs</p>
                <p className='nft-mobile-text' style={{ fontSize: '20px' }}>Unlock unique experiences, own digital art, access exclusive memberships, and so much more with your NFTs</p>
                <p className='nft-mobile-text' style={{ fontSize: '35px', color: '#0901e9', textAlign: 'left' }}>Support for 600M+ NFTs means no more app switching</p>
                <p className='nft-mobile-text' style={{ fontSize: '20px', textAlign: 'left' }}>Enjoy all your NFTs in one place. Store, showcase, and manage your NFTs across multiple blockchains.</p>
                <img alt='img'  src='https://trustwallet.com/_next/static/media/raw.5a662d03.svg' width={180} height={180} style={{ paddingTop: '10px', paddingBottom: '10px' }}></img>
                <p className='nft-mobile-text' style={{ fontSize: '30px' }}>Deposit, manage and explore</p>
                <p className='nft-mobile-text' style={{ fontSize: '20px' }}>Trust Wallet offers you an easy way to keep all your favourite NFTs in one place.</p>
                <img alt='img'  src='https://trustwallet.com/_next/static/media/raw.b8860ba8.svg' width={180} height={180} style={{ paddingTop: '10px', paddingBottom: '10px' }}></img>
            </div>
            <div style={{ backgroundColor: 'white' }}>
                <p className='nft-mobile-text' style={{ fontSize: '30px', color: 'black', paddingTop: '20px' }}>Stake your crypto & earn rewards in just a few steps</p>
                <p className='nft-mobile-text' style={{ fontSize: '18px', color: 'black' }}>By staking your crypto, you’re not just growing your portfolio, but also helping secure blockchain networks for everyone.</p>
                <img alt='img'  src='https://trustwallet.com/_next/static/media/raw.4c0336e1.svg' width={180} height={180} style={{ paddingTop: '10px', paddingBottom: '10px' }}></img>
            </div>
            <div>
                <p className='nft-mobile-text' style={{ fontSize: '30px', color: '#0901e9' }}>Contribute to Blockchain</p>
                <p className='nft-mobile-text' style={{ fontSize: '20px' }}>Participate in network security and decentralization.</p>
                <img alt='img'  src='https://trustwallet.com/_next/static/media/raw.5a662d03.svg' width={180} height={180} style={{ paddingTop: '10px', paddingBottom: '10px' }}></img>
                <p className='nft-mobile-text' style={{ fontSize: '30px', color: '#0901e9' }}>Learn about DeFi</p>
                <p className='nft-mobile-text' style={{ fontSize: '20px' }}>Explore more chains and expand your earning journey across DeFi.</p>
                <img alt='img'  src='https://trustwallet.com/_next/static/media/raw.7a06cf07.svg' width={180} height={180} style={{ paddingTop: '10px', paddingBottom: '20px' }}></img>
            </div>
            <div style={{ backgroundColor: 'white' }}>
                <p className='nft-mobile-text' style={{ fontSize: '30px', color: 'black', paddingTop: '20px', textAlign: 'left' }}>Download<br></br><span style={{ color: '#0901e9' }}>Trust Wallet</span></p>
                <p className='nft-mobile-text' style={{ fontSize: '20px', color: 'black', textAlign: 'left' }}>The Trust Wallet is available as a mobile app and desktop browser extension</p>
                <div style={{ display: 'flex', padding: '0px', justifyContent: 'center', margin: 'auto' }}>
                    <a href='https://apps.apple.com/app/apple-store/id1288339409?mt=8'><img alt='img'  src='https://trustwallet.com/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Fimage.a073b4c3.png&w=128&q=75' height={64} style={{ padding: '10px', width: '64px' }}></img></a>
                    <a href='https://play.google.com/store/apps/details?id=com.wallet.crypto.trustapp'><img alt='img'  src='https://trustwallet.com/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Fimage.5ee64b2e.png&w=128&q=75' height={64} style={{ padding: '10px', width: '64px' }}></img></a>
                    <a href='https://chrome.google.com/webstore/detail/trust-wallet/egjidjbpglichdcondbcbdnbeeppgdph'><img alt='img'  src='https://assets.zyrosite.com/cdn-cgi/image/format=auto,w=444,h=442,fit=crop,trim=0;0;2.3063063063063063;0/YBgpgeD2wLhayB6z/google_chrome_icon_-2011-YbNaN46jyEIDD581.png' height={64} style={{ padding: '10px', width: '64px' }}></img></a>
                    <a href='https://trustwallet.com/download/apk'><img alt='img'  src='https://assets.zyrosite.com/cdn-cgi/image/format=auto,w=448,h=446,fit=crop,trim=4.571428571428571;0;0;0/YBgpgeD2wLhayB6z/apk_format_icon_-2014-2019-A1aKaz6rEGtj72vk.png' height={64} style={{ padding: '10px', width: '64px' }}></img></a>
                </div>
            </div>
        </div>
    );
};

export default NFTMobile;
